import React from 'react';
import { Helmet } from "react-helmet";
import { useRef } from 'react';
import useEffectOnce from 'hooks/useEffectOnce';
import { useHistory } from 'react-router-dom';
import './Policies.scss';

export default (props) => {
  const history = useHistory();
  const appendix = useRef(null);
  const clause7 = useRef(null);

  useEffectOnce(() => {
    if (history.location.hash.indexOf("products-list") > -1) {
      const section = document.querySelector('#products-list');
      if (section !== null) { section.scrollIntoView({ behavior: 'smooth', block: 'start' }); }
    }

    if (history.location.hash.indexOf("clause-12") > -1) {
      const section = document.querySelector('#clause-12');
      if(section !== null) { section.scrollIntoView({ behavior: 'smooth', block: 'start' }); }
    }

    if (history.location.hash.indexOf("clause-13") > -1) {
      const section = document.querySelector('#clause-13');
      if(section !== null) { section.scrollIntoView({ behavior: 'smooth', block: 'start' }); }
    }
  });

  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: 'smooth'
    })
  }

  return <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Terms & Conditions | Cadbury FC - The Winning Pass</title>
    </Helmet>
    <section className="layout-general layout-wrapper layout-general--full">
      <div className="layout-container layout-container--wide layout-container--center">
        <div className='policies'>
          <h1 className="type-h1">Terms & Conditions</h1>

          <div className='policies__content'>
            <ol>
              <li>
                <p>This Promotion is open to residents of the United Kingdom (excluding Channel Islands and Isle of Man) aged 18 or over, excluding employees of the Promoter, its agents and anyone professionally associated with this Promotion. </p>
              </li>

              <li>
                <p>Purchase is necessary. Please retain an itemised receipt that states the time and date of purchase prior to entry, but within the Promotion Period, as this may be required for validation and in order for the winner to receive their Prize. Internet access and a valid email address are required. </p>
              </li>

              <li>
                <p><strong>Promotion Period:</strong></p>
                <ol type="a">
                  <li><p><strong>Prize Draw and Winning Moments:</strong> Enter between 10:00 GMT on the 6 January 2025 and 16:59 GMT on the 21 March 2025 inclusive. </p></li>
                </ol>
              </li>

              <li>
                <p><strong>To Enter:</strong></p>
                <ol type="a">
                  <li><p><strong>Level 1 Prize Draw:</strong> Purchase a Promotional Product, see <button type='button' className='button button--text-link' onClick={() => scrollToSection(clause7)}>clause 7</button> for a list of Promotional Products. Visit <a href="https://thewinningpass.cadbury.co.uk" rel="noopener noreferrer" target="_blank">thewinningpass.cadbury.co.uk</a> and complete the online form providing the bar code and batch code along with your details (full name, email address, mobile (optional) and where you made your purchase from) and choose your Participating Club and the Level 1 Prize Draw you would like to be entered into.  </p></li>
                  <li><p><strong>Level 2/3 Winning Moments:</strong> All valid entries are also submitted into a winning moments promotion.</p></li>
                </ol>
              </li>

              <li>
                <p><strong>Level 1 Prize Draw Participating Clubs:</strong></p>
                <ol type="a">
                  <li>Arsenal FC</li>
                  <li>Chelsea FC</li>
                  <li>Leeds United FC</li>
                  <li>Liverpool FC</li>
                  <li>Manchester United</li>
                  <li>Tottenham Hotspur</li>
                </ol>
              </li>

              <li>
                <p><strong>Cadbury Partner Clubs:</strong></p>
                <ol type="a">
                  <li>Arsenal FC</li>
                  <li>Celtic FC</li>
                  <li>Chelsea FC</li>
                  <li>Leeds United FC</li>
                  <li>Liverpool FC</li>
                  <li>Manchester United</li>
                  <li>Rangers FC</li>
                  <li>Tottenham Hotspur</li>
                </ol>
              </li>

              <li ref={clause7}>
                <p><strong>Promotional Products:</strong> Please see <button type='button' className='button button--text-link' onClick={() => scrollToSection(appendix)}>Appendix 1</button> for details.</p>
              </li>

              <li>
                <p>Maximum of 1 entry is permitted per person per day during the Promotion Period. Maximum of 10 entries per person during the entire Promotion Period. One itemised receipt per entry must state the time and date of purchase, which must pre-date the entry date and time, but be within the Promotion Period. No household may win more than 1 x Level 1 Prize and 2 x Level 2 Prizes and 3 x Level 3 Prizes. </p>
              </li>

              <li>
                <p>A person is defined by name and email address and the combination needs to be unique to a single participant for a prize claim to be valid. Postal addresses used to claim may be checked and verified via Royal Mail PAF for eligibility. Winners are provisional at point of win/claim and subject to prize claim verification to qualify win.</p>
              </li>

              <li>
                <p>It is the responsibility of the participant to provide their correct, up-to-date details when entering the Promotion and/or confirming acceptance of the Prize in order for their Prize to be processed. The Promoter cannot be held responsible for winners failing to supply accurate information which affects entry into the Promotion, Prize acceptance or delivery of their Prize.</p>
              </li>

              <li>
                <p><strong>Winner Selection:</strong></p>
                <ol type="a">
                  <li><p><strong>Level 1 Prize Draw: </strong> 1 Winner per Participating Club Draw, 6 in total, will be randomly selected from all valid entries received for each Participating Club during the Promotion Period. For your total confidence, the Prize draws will be conducted by PromoVeritas, the independent promotional verification service, within 5 working days of the end of the Prize Draw and Winning Moments Promotion Period.</p></li>
                  <li><p><strong>Level 2 Winning Moments: </strong> During the Promotion Period, 201 “winning moments” (days, hours, minutes, seconds), a minimum of 18 winning moments in Week 1, 2, 3, 4, 5, 6, 7 & 10 and a minimum of 19 winning moments in Week 8, 9, & 11 have been randomly selected by an independent verification service. The first entry of a valid entry on or after that winning moment will be awarded the Prize. If no entry is received between one winning moment to the next, the winning moment will not be rolled over.  All 201 Prizes are available to be won. </p></li>
                  <li><p><strong>Level 3 Winning Moments: </strong> During the Promotion Period, 875 “winning moments” (days, hours, minutes, seconds), have been randomly selected by an independent verification service. The first entry of a valid entry on or after that winning moment will be awarded the Prize. If no entry is received between one winning moment to the next, the winning moment will not be rolled over.  All 875 Prizes are available to be won. </p></li>
                </ol>
              </li>

              <li id="clause-12">
                <p><strong>Prizes:</strong></p>
                <ol type="a" className="reduced-list">
                  <li>
                    <p><strong>Level 1 Prize Draw:</strong> There will be 6 winners, 1 per Participating Club. Each winner can choose one of the below Prizes for their chosen club (selected at the point of entry).</p>
                      <br></br>
                      <ol type="i">
                        <li><p><strong>1 x VIP matchday experience</strong> for 1 x winner and 3 x guests which includes the following:</p></li>
                        <ol type="1">
                          <li><p>1 x Money Can’t Buy Experience (exact experience will be club dependent)</p></li>
                          <li><p>VIP Matchday Tickets for 1 winner + 3 guests</p></li>
                          <li><p>1 x Club Legend appearance</p></li>
                          <li><p>1 x night accommodation (minimum 4*) for winner + 3 guests in two double occupancy rooms inclusive of breakfast (accommodation available on the night of OR night before the experience).</p></li>
                          <li><p>Return standard travel for 1 x Winner & 3 x Guest from one location to the experience.</p></li>
                          <li><p>1 winner + 3 guests standard travel to/from stadium on match day</p></li>
                          <li><p>1 x Signed 2024/25 Season club football shirt (for winner only)</p></li>
                          <li><p>1 x Club merchandise bundle (shirt, shorts, socks, scarf, hat kit bag) (for winner only) </p></li>
                          <li><p>1 x Cadbury Hamper</p></li>
                        </ol>
                        <p><strong>OR</strong></p>
                        <br></br>
                        <li><p><strong>1 x Ultimate Fan Experience</strong> for 1 x winner and 3 x guests which includes the following:</p></li>
                        <ol type="1">
                          <li><p>1 x Money Can’t Buy Experience (exact experience will be club dependent)</p></li>
                          <li><p>General Admission Matchday Tickets for 1 winner + 3 guests</p></li>
                          <li><p>Spending voucher for match day food and beverage up to £250 amount</p></li>
                          <li><p>1 x Club Legend appearance</p></li>
                          <li><p>1 x night accommodation (minimum 4*) for winner + 3 guests in two double occupancy rooms inclusive of breakfast (accommodation available on the night of OR night before the experience).</p></li>
                          <li><p>Return standard travel for 1 x Winner & 3 x Guest from one location to the experience.</p></li>
                          <li><p>1 winner + 3 guests standard travel to/from stadium on match day</p></li>
                          <li><p>1 x Signed 2024/25 Season club football shirt (for winner only)</p></li>
                          <li><p>1 x Club merchandise bundle (shirt, shorts, socks, scarf, hat kit bag) (for winner only) </p></li>
                          <li><p>1 x Cadbury Hamper</p></li>
                        </ol>
                        <p><strong>OR</strong></p>
                        <br></br>
                        <li><p><strong>1 x Behind The Scenes Day</strong> for 1 x winner and 3 x guests which includes the following:</p></li>
                        <ol type="1">
                          <li><p>1 x Money Can’t Buy Experience (exact experience will be club dependent)</p></li>
                          <li><p>VIP Matchday Tickets for 1 winner + 3 guests</p></li>
                          <li><p>1 x Club Legend appearance</p></li>
                          <li><p>1 x night accommodation (minimum 4*) for winner + 3 guests in two double occupancy rooms inclusive of breakfast (accommodation available on the night of OR night before the experience).</p></li>
                          <li><p>Return standard travel for 1 x Winner & 3 x Guest from one location to the experience.</p></li>
                          <li><p>1 winner + 3 guests standard travel to/from stadium on match day</p></li>
                          <li><p>1 x Signed 2024/25 Season club football shirt (for winner only)</p></li>
                          <li><p>1 x Club merchandise bundle (shirt, shorts, socks, scarf, hat kit bag) (for winner only) </p></li>
                          <li><p>1 x Cadbury Hamper</p></li>
                        </ol>
                      </ol>
                  </li>
                  <p><strong>Prize fulfilment subject to Covid19 rules and regulations / club restrictions and terms and conditions around access and dates.</strong></p>
                  <p><strong>Any additional prize(s) are at the discretion of the Participating Club's and may vary between each Participating Club.</strong></p>
                  <br></br>
                  <li>
                    <p><strong>Level 2 Winning Moments: </strong> : 201 x pairs of matchday tickets for the 2024/25 football season for Cadbury Partner Clubs, subject to availability. </p>
                    <br></br>
                    <ol type="i">
                      <li><p>The tickets are allocated week-by-week on a first come first served basis, as such the Promoter cannot guarantee that your first choice will be fulfilled. Please see below for weekly breakdown:</p></li>
                      <p><strong>Week 1 (06/01/2025-12/01/2025):</strong></p>
                      <br></br>
                      <ul>
                          <li><p>2 x Pairs of General Admission tickets to Liverpool FC vs Wolverhampton Wanderers FC on 15/02/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Leeds United FC vs Sunderland Athletic FC on 15/02/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Birmingham City FC vs Charlton Athletic FC on 15/02/2025</p></li>
                          <li><p>1 x Pair of General Admission tickets to Celtic FC vs Dundee United on 15/02/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Tottenham Hotspur vs Manchester United on 15/02/2025</p></li>
                          <li><p>1 x Pair of General Admission tickets to Rangers FC vs St Mirren on 22/02/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Tottenham Hotspur vs Manchester City on 25/02/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Liverpool FC vs Newcastle on 26/02/2025</p></li>
                          <li><p>3 x Pairs of General Admission tickets to Chelsea FC vs Southampton on 26/02/2025</p></li>
                          <li><p>1 x Pair of General Admission tickets to Manchester United vs Ipswich Town on 26/02/2025</p></li>
                      </ul>

                      <p><strong>Week 2 (13/01/2025-19/01/2025):</strong></p>
                      <br></br>
                      <ul>
                          <li><p>3 x Pairs of General Admission tickets to Liverpool FC vs Wolverhampton Wanderers FC on 15/02/2025</p></li>
                          <li><p>1 x Pair of General Admission tickets to Celtic FC vs Dundee United on 15/02/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Tottenham Hotspur vs Manchester United on 15/02/2025</p></li>
                          <li><p>1 x Pair of General Admission tickets to Rangers FC vs St Mirren on 22/02/2025</p></li>
                          <li><p>1 x Pair of General Admission tickets to Tottenham Hotspur vs Manchester City on 25/02/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Liverpool FC vs Newcastle on 26/02/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Manchester United vs Ipswich Town on 26/02/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Leeds United FC vs West Bromwich Albion on 01/03/2025</p></li>
                          <li><p>1 x Pair of General Admission tickets to Birmingham City FC vs Wycombe Wanderers on 01/03/2025</p></li>
                          <li><p>3 x Pairs of General Admission tickets to Chelsea FC vs Leicester on 08/03/2025</p></li>
                      </ul>

                      <p><strong>Week 3 (20/01/2025-26/01/2025):</strong></p>
                      <br></br>
                      <ul>
                          <li><p>1 x Pair of General Admission tickets to Liverpool FC vs Wolverhampton Wanderers FC on 15/02/2025</p></li>
                          <li><p>1 x Pair of General Admission tickets to Celtic FC vs Dundee United on 15/02/2025</p></li>
                          <li><p>1 x Pair of General Admission tickets to Tottenham Hotspur vs Manchester City on 25/02/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Liverpool FC vs Newcastle on 26/02/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Manchester United vs Ipswich Town on 26/02/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Celtic FC vs Aberdeen on 26/02/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Rangers FC vs Motherwell on 01/03/2025</p></li>
                          <li><p>4 x Pairs of General Admission tickets to Liverpool FC vs Southampton FC on 08/03/2025</p></li>
                          <li><p>1 x Pair of General Admission tickets to Birmingham City FC vs Lincoln City FC on 08/03/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Manchester United vs Arsenal on 08/03/2025</p></li>
                      </ul>

                      <p><strong>Week 4 (27/01/2025-02/02/2025):</strong></p>
                      <br></br>
                      <ul>
                          <li><p>2 x Pairs of General Admission tickets to Rangers FC vs Motherwell on 01/03/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Liverpool FC vs Southampton FC on 08/03/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Manchester United vs Arsenal on 08/03/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Tottenham Hotspur vs Bournemouth FC on 08/03/2025</p></li>
                          <li><p>1 x Pair of General Admission tickets to Leeds United vs Millwall FC on 12/03/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Arsenal vs Chelsea on 15/03/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Celtic FC vs Rangers FC on 15/03/2025</p></li>
                          <li><p>1 x Pair of General Admission tickets to Birmingham City FC vs Shrewsbury Town on 29/03/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Leeds United vs Swansea City on 29/03/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Liverpool FC vs Everton FC on 02/04/2025</p></li>
                      </ul>

                      <p><strong>Week 5 (03/02/2025-09/02/2025):</strong></p>
                      <br></br>
                      <ul>
                          <li><p>2 x Pairs of General Admission tickets to Manchester United vs Arsenal on 08/03/2025</p></li>
                          <li><p>1 x Pair of General Admission tickets to Tottenham Hotspur vs Bournemouth FC on 08/03/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Leeds United vs Millwall FC on 12/03/2025</p></li>
                          <li><p>1 x Pair of General Admission tickets to Arsenal vs Fulham on 01/04/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Liverpool FC vs Everton FC on 02/04/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Celtic FC vs Hearts FC on 29/03/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Chelsea FC vs Tottenham Hotspur on 02/04/2025</p></li>
                          <li><p>3 x Pairs of General Admission tickets to Tottenham Hotspur vs Southampton FC on 05/04/2025</p></li>
                          <li><p>1 x Pair of General Admission tickets to Birmingham City FC vs Barnsley FC on 05/04/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Rangers FC vs Hibernian on 05/04/2025</p></li>
                      </ul>

                      <p><strong>Week 6 (10/02/2025-16/02/2025):</strong></p>
                      <br></br>
                      <ul>
                          <li><p>1 x Pair of General Admission tickets to Manchester United vs Arsenal on 08/03/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Liverpool FC vs Everton FC on 02/04/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Chelsea FC vs Tottenham Hotspur on 02/04/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Rangers FC vs Hibernian on 05/04/2025</p></li>
                          <li><p>3 x Pairs of General Admission tickets to Manchester United vs Manchester City on 05/04/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Liverpool FC vs West Ham FC on 12/04/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Chelsea FC vs Ipswich United on 12/04/2025</p></li>
                          <li><p>1 x Pair of General Admission tickets to Arsenal vs Brentford on 12/04/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Leeds United vs Preston North End on 12/04/2025</p></li>
                          <li><p>1 x Pair of General Admission tickets to Birmingham City FC vs Crawley United on 18/04/2025</p></li>
                      </ul>

                      <p><strong>Week 7 (17/02/2025-23/02/2025):</strong></p>
                      <br></br>
                      <ul>
                          <li>2 x Pairs of General Admission tickets to Manchester United vs Manchester City on 05/04/2025</li>
                          <li>2 x Pairs of General Admission tickets to Liverpool FC vs West Ham FC on 12/04/2025</li>
                          <li>1 x Pair of General Admission tickets to Chelsea FC vs Ipswich United on 12/04/2025</li>
                          <li>2 x Pairs of General Admission tickets to Celtic FC vs Kilmarnock on 12/04/2025</li>
                          <li>2 x Pairs of General Admission tickets to Manchester United vs Wolverhampton Wanderers FC on 19/04/2025</li>
                          <li>2 x Pairs of General Admission tickets to Tottenham Hotspur vs Nottingham Forest on 19/04/2025</li>
                          <li>2 x Pairs of General Admission tickets to Leeds United vs Stoke City on 21/04/2025</li>
                          <li>3 x Pairs of General Admission tickets to Liverpool FC vs Tottenham Hotspur on 26/04/2025</li>
                          <li>1 x Pair of General Admission tickets to Arsenal vs Crystal Palace on 26/04/2025</li>
                          <li>1 x Pair of General Admission tickets to Chelsea FC vs Everton FC on 26/04/2025</li>
                      </ul>

                      <p><strong>Week 8 (24/02/2025-02/03/2025):</strong></p>
                      <br></br>
                      <ul>
                          <li><p>2 x Pairs of General Admission tickets to Manchester United vs Manchester City on 05/04/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Liverpool FC vs West Ham FC on 12/04/2025</p></li>
                          <li><p>3 x Pairs of General Admission tickets to Manchester United vs Wolverhampton Wanderers FC on 19/04/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Tottenham Hotspur vs Nottingham Forest on 19/04/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Leeds United vs Stoke City on 21/04/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Liverpool FC vs Tottenham Hotspur on 26/04/2025</p></li>
                          <li><p>1 x Pair of General Admission tickets to Arsenal FC vs Crystal Palace on 26/04/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Chelsea FC vs Everton FC on 26/04/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Leeds United vs Bristol City on 26/04/2025</p></li>
                          <li><p>1 x Pair of General Admission tickets to Birmingham City vs Mansfield Town on 26/04/2025</p></li>
                      </ul>

                      <p><strong>Week 9 (03/03/2025-09/03/2025):</strong></p>
                      <br></br>
                      <ul>
                          <li><p>2 x Pairs of General Admission tickets to Manchester United vs Wolverhampton Wanderers FC on 19/04/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Liverpool FC vs Tottenham Hotspur on 26/04/2025</p></li>
                          <li><p>1 x Pair of General Admission tickets to Arsenal FC vs Crystal Palace on 26/04/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Chelsea FC vs Everton FC on 26/04/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Leeds United vs Bristol City on 26/04/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Arsenal FC vs Bournemouth FC on 03/05/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Chelsea FC vs Liverpool FC on 03/05/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Tottenham Hotspur vs Crystal Palace on 10/05/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Liverpool FC vs Arsenal FC on 10/05/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Manchester United vs West Ham United on 10/05/2025</p></li>
                      </ul>

                      <p><strong>Week 10 (10/03/2025-16/03/2025):</strong></p>
                      <br></br>
                      <ul>
                          <li><p>1 x Pair of General Admission tickets to Leeds United vs Bristol City on 26/04/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Chelsea FC vs Liverpool FC on 03/05/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Tottenham Hotspur vs Crystal Palace on 10/05/2025</p></li>
                          <li><p>3 x Pairs of General Admission tickets to Liverpool FC vs Arsenal FC on 10/05/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Manchester United vs West Ham United on 10/05/2025</p></li>
                          <li><p>1 x Pair of General Admission tickets to Tottenham Hotspur vs Brighton & Hove Albion on 25/05/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Chelsea FC vs Manchester United on 18/05/2025</p></li>
                          <li><p>3 x Pairs of General Admission tickets to Liverpool FC vs Crystal Palace on 25/05/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Manchester United vs Aston Villa on 25/05/2025</p></li>
                      </ul>

                      <p><strong>Week 11 (17/03/2025-21/03/2025):</strong></p>
                      <br></br>
                      <ul>
                          <li><p>2 x Pairs of General Admission tickets to Chelsea FC vs Liverpool FC on 03/05/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Liverpool FC vs Arsenal FC on 10/05/2025</p></li>
                          <li><p>3 x Pairs of General Admission tickets to Manchester United vs West Ham United on 10/05/2025</p></li>
                          <li><p>2 x Pairs of General Admission tickets to Chelsea FC vs Manchester United on 18/05/2025</p></li>
                          <li><p>3 x Pairs of General Admission tickets to Liverpool FC vs Crystal Palace on 25/05/2025</p></li>
                          <li><p>3 x Pairs of General Admission tickets to Manchester United vs Aston Villa on 25/05/2025</p></li>
                          <li><p>1 x Pair of General Admission tickets to Arsenal FC vs Newcastle United on 18/05/2025</p></li>
                          <li><p>3 x Pairs of General Admission tickets to Tottenham Hotspur vs Brighton & Hove Albion on 25/05/2025</p></li>
                      </ul>

                    </ol>
                  </li>
                  <li><p><strong>Level 3 Instant Win: </strong> There are 875 Prizes available to be won. Prizes will be randomly allocated. </p></li>
                  <ol type="i">
                    <li><p>375 x £20 Lifestyle vouchers</p></li>
                    <li><p>500 x £10 Lifestyle vouchers </p></li>
                  </ol>

                </ol>
              </li>

              <li id="clause-13">
                <p><strong>Further Entry and Prize Details and Conditions: </strong></p>
                <ol type="a">
                  <li><p>The Promoter may request that all winners participate in reasonable promotional activity (such as publicity and photography) surrounding the winning of the prize, free of charge, and they consent to the Promoter using their name and images in promotional material. The winners are under no obligation to participate and may decline this request. Participation is at the winner's discretion and is not a condition of prize acceptance.</p></li>
                  <li><p>Any guests under the age of 18 must be accompanied by a parent or legal guardian.</p></li>
                  <li><p><strong>Level 1 Prize:</strong></p></li>
                  <ol type="i" className="reduced-list">
                    <li><p>A guest cannot be a Winner of another Level 1 Prize Draw Prize.</p></li>
                    <li><p>The experiences must be taken before 25th May 2025. The experiences are subject to the chosen Participating Club's availability. The Promoter will endeavour to provide as much visibility as possible for the Prize date, although timings may only be confirmed (and are liable to move) days in advance, subject to the Participating Club's availability. Neither the Promoter nor the Participating Club will be liable in any way for any cancellation or postponement of the Prize.</p></li>
                    <li><p>Across all Participating Clubs involved in the promotion the Prize will take place on a date pre-determined by the Participating Club / Promoter (with winners unable to change) in 2025. Winners may need to attend at short notice anytime from March 2025 onwards.</p></li>
                    <li><p>In order to facilitate the smooth-running of the Prize, there will be a minimum of   1 representative of the Promoter and the Participating Club during the Experience. In the event that, in the opinion of the Promoter, the winner or their guests behave and/or speak in an inappropriate manner or ask questions that have not been pre-approved, the representatives of the Promoter or Participating Club will issue an initial warning. If the behaviour and/or speech continues, the representatives of the Promoter or Participating Club will terminate the meet & greet and no alternative Prize, or any other form of compensation will be provided. The decision of the Promoter is final, and no correspondence will be entered into.</p></li>
                    <li><p>The prize will include standard class travel to and from the experience venue a minimum 4* hotel stay for 1 night (in 2x twin or 2x double rooms) for the winner and 3 guests as well as food and drink at the experience.</p></li>
                    <li><p>Travel and accommodation will be arranged by the promoter’s agency and will be at their sole discretion.</p></li>
                    <li><p>A valid credit or debit card may be required for the winner to check in to their room and will be used to guarantee any incidentals such as in- room calls or services.</p></li>
                    <li><p>The winner is solely responsible for any additional charges incurred during their stay, including, without limitation, costs of meals, drinks, additional nights and services together with related taxes.</p></li>
                    <li><p>The signed shirts will be signed by at least one player from the Participating Club’s current squad. However, there is no choice of specific players. There will be no choice of shirt size.</p></li>
                  </ol>
                  <li><p><strong>Level 1 and 2 Prizes:</strong></p></li>
                  <ol type="i" className="reduced-list">
                    <li><p>The Winner will be subject to a name check against any Participating Club or Cadbury Partner Club, venue or sporting Banning Orders as well as the National Police Database for those who may be subject to a court banning order.</p></li>
                    <li><p>If any of the guests are under the age of 18, then they should be accompanied by a responsible adult and with their guardian's consent.</p></li>
                    <li><p>Except mobile phones used for personal and private use only, you may not bring into (or use within) any Participating Club or Cadbury Partner Club's stadium any equipment that is capable of recording or transmitting any audio, visual or audio-visual material or any information or data in relation to a match or any aspect of it.</p></li>
                    <li><p>For the avoidance of doubt, the Prize does not include travel/transportation, accommodation, food, beverages, souvenirs, gratuities, car parking charges, excess baggage, additional excursions and attractions or any other costs of a personal nature (including spending money) that are not explicitly set out in these Terms and Conditions and neither the Promoter nor any provider of any part of the Prize will be responsible for any such costs. For the avoidance of doubt, dinner at the hotel will not be included. </p></li>
                    <li><p>Redemption of all Prizes is subject to Government guidelines and lockdown measures which can be found at: <a href="https://www.gov.uk/coronavirus" rel="noopener noreferrer" target="_blank"> https://www.gov.uk/coronavirus</a>.</p></li>
                    <li><p>If Government guidelines restrict redemption of the Prize in its current form, the Promoter will work with the winner to discuss moving the Prize to a later date or issuing an alternative Prize.</p></li>
                    <li><p>At all times, the Promoter will have the discretion as to how to implement the Prize fulfilment.</p></li>
                    <li><p>Once dates, tickets, or names are booked, they cannot be altered or changed except at the Promoter’s sole discretion.</p></li>
                    <li><p>The dates available for the experiences are not guaranteed and are subject to C19 / Participating Club or Cadbury Partner Club restrictions around access and dates.</p></li>
                    <li><p>The Prize will be arranged by email/phone between the Promoter’s agency and the individual winners. The Promoter’s agency will make all reasonable efforts to provide the winner with details of the Prize at the earliest possible opportunity. The Promoter will have final discretion on all elements and aspects of how the Prize is organised. Once the Prize details, including dates, locations and, where applicable is confirmed, this cannot be amended or altered by the winner.</p></li>
                    <li><p>The Winner is responsible for the behaviour of themselves and their guests whilst taking the Prize. The Promoter reserves the right in its absolute discretion to exclude a winner and/or their guests from participation in any aspect of the Prize if any party fails to comply with the directions of the Promoter or any companies associated with the Prize or if a winner and/or their guests act in a manner that is dangerous to themselves or to the public or is, in the opinion of the Promoter, anti-social in any manner or which causes a disturbance or nuisance to others. Failure to comply with these Terms and Conditions may result in refused entry, or the ticket holders being required to leave the event/venue.</p></li>
                    <li><p>Winners must inform the Promoter of any wheelchair or any similar access needs.</p></li>
                    <li><p>Tickets may be in the form of e-tickets and winners may require a smartphone to claim and download their tickets. Where tickets are not available as e-tickets, winners will need to collect their tickets on-site.</p></li>
                    <li><p>Exact match dates may be subject to change by the Participating Club or Cadbury Partner Club. If there is a change in date, the Promoter will communicate this to the winners/entrants and the Promoter is not liable for any date changes. Winners/entrants will not be able to exchange or transfer tickets. </p></li>
                    <li><p>Winners will be subject to a name check against any Participating Club or Cadbury Partner Club, venue or sporting Banning Orders as well as the National Police Database for those who may be subject to a court banning order. </p></li>
                    <li><p>All Prizes are only available to home or neutral supporters of the Participating Club or Cadbury Partner Club so all necessary checks/ conditions must be adhered to. The Promoter reserves the right to disqualify any potential winner and/or guest on the basis of the findings resulting from a background or information check and award the tickets to an alternative winner. </p></li>
                    <li><p>Entry to any stadium is subject to the normal terms and conditions of entry of the applicable stadium and/or football club. All tickets for preferred club selections will be “home” tickets. Away fans are not permitted in the “home” section of a stadium. Winners are subject to screening by football clubs based on their conditions of entry and we are not liable if you or any person accompanying you is refused entry because you do not meet these conditions.</p></li>
                    <li><p>Attending fans are required to not wear opposition colours and doing so may result in entry to the stadium not being permitted.</p></li>
                    <li><p>Each ticket is issued by the relevant Participating Club or Cadbury Partner Club subject to the terms and conditions of entry, which are available from the Club and will be provided to you with your Prize. The use of a ticket to enter a Club's stadium constitutes acceptance of that Participating Club or Cadbury Partner Club’s terms and conditions of entry. The ticket shall remain at all times the property of the issuing Participating Club or Cadbury Partner Club.</p></li>
                    <li><p>For the avoidance of doubt, the Prize does not include, travel, accommodation, souvenirs, gratuities, car parking charges, excess baggage, additional excursions and attractions or any other costs of a personal nature (including spending money), or any costs incurred due to Covid-19, that are not explicitly set out in these Terms and Conditions and neither the Promoter nor any provider of any part of the Prize will be responsible for any such costs. For the avoidance of doubt, dinner at the hotel will not be included. </p></li>
                    <li><p>The venue reserves the right to refuse entry to any person who does not adhere to their terms, checks and requirements, regardless of possession of a valid ticket.</p></li>
                    <li><p>The Promoter reserves the right to disqualify any entry from a person who is the subject of a stadium banning order or is otherwise prohibited from attending football matches by any professional association football club or any competent authority.</p></li>
                    <li><p>If for any reason the event is not capable of running as planned, such as cancellation or any other cause beyond the control of the Promoter which affects the running or conduct of this promotion, the Promoter may in its sole discretion cancel the Prize and not re-award any additional tickets to the winner or reimburse any incurred expenses by the winner as a result of winning or accepting the Prize.</p></li>
                    <li><p>Once tickets have been issued, the Promoter, will not be liable for any failure to comply with its obligations caused by (but not limited to) weather conditions, fire, flood, strike, hurricane, industrial dispute, war, terrorist activity, hostilities, political unrest, riots, civil commotion, illness of a performer or any other circumstances beyond the control of the Promoter.</p></li>
                  </ol>
                  <li><p><strong>Level 3 Prizes:</strong></p></li>
                  <p>Lifestyle voucher terms and conditions can be found here. The voucher will expire 18 months from the date of Prize Acceptance.</p>
                </ol>
              </li>
              <li>
                <p><strong>Winner Notification: </strong></p>
                <ol type="a">
                  <li>
                    <p><strong>Level 1 Prize Draw: </strong>Provisional winners will be contacted via the email address provided on entry within 5 working days of Winner Selection and will be asked to provide evidence of identity and eligibility. Reasonable efforts will be made to contact the winners. If a winner does not respond to the initial contact and provide valid evidence of their identification for verification within 5 days, we reserve the right to disqualify that entrant and award the Prize to a reserve selected in the same manner. Reserve winners may have less time to respond.</p>
                  </li>
                  <li>
                    <p><strong>Level 2 Winning Moments: </strong>The provisional winning entrants will be instantly notified on screen that they are a winner. Winners will also receive a confirmation email to their email address used upon entry, confirming their Prize and will be required to follow the instructions within 48 hours of initial contact. The Prizes are allocated week-by-week on a first come first served basis, as such the Promoter cannot guarantee that your first choice will be fulfilled. If a provisional winner does not follow the instructions in the confirmation email within 48 hours of initial contact, the Promoter reserves the right to disqualify those provisional winners. </p>
                    <p>Any claims received that don’t meet these requirements will be void and invalid and the Prize will not be returned to the Promotion Prize pool.</p>
                  </li>
                  <li>
                    <p><strong>Level 3 Winning Moments: </strong>: The provisional winning entrants will be instantly notified on screen that they are a winner. Winners will also receive a confirmation email to their email address used upon entry, confirming their prize and will be required to follow the instructions within 14 days of initial contact. If a provisional winner does not follow the instructions in the confirmation email within 14 days of initial contact, the Promoter reserves the right to disqualify those provisional winners. </p>
                    <p>Any claims received that don’t meet these requirements will be void and invalid and the Prize will not be returned to the Promotion Prize pool.</p>
                  </li>
                </ol>
              </li>
              <li>
                <p><strong>Prize Acceptance: </strong></p>
                <ol type="a">
                  <li>
                    <p><strong>Level 1 Prize Draw: </strong>Once eligibility of a Prize is confirmed and the Prize is accepted, winners will be notified within 28 days of acceptance of the Prize to arrange fulfilment of the Prize. In the unlikely event that a winner does not receive their notification within 28 days, the winners will have a further 28 days to inform the Promoter by emailing <a href="mailto:thewinningpass@promowinners.com" target="_blank" rel="noopener noreferrer">thewinningpass@promowinners.com</a>. If a winner does not do so, the Promoter reserves the right to not reissue the Prize or limit its value at its sole discretion.</p>
                  </li>
                  <li>
                    <p><strong>Level 2 Winning Moments: </strong>Once eligibility of a Prize is confirmed and the Prize is accepted, winners will be notified within 20 days of acceptance of the Prize to arrange fulfilment of the Prize. In the unlikely event that a winner does not receive their notification within 20 days, the winners will have a further 20 days to inform the Promoter by emailing <a href="mailto:thewinningpass@promowinners.com" target="_blank" rel="noopener noreferrer">thewinningpass@promowinners.com</a>. If a winner does not do so, the Promoter reserves the right to not reissue the Prize or limit its value at its sole discretion.</p>
                  </li>
                  <li>
                    <p><strong>Level 3 Winning Moments: </strong>: Once eligibility of a Prize is confirmed and the Prize is accepted, winners will be notified within 28 days of acceptance of the Prize to arrange fulfilment of the Prize. In the unlikely event that a winner does not receive their notification within 28 days, the winners will have a further 28 days to inform the Promoter by emailing <a href="mailto:thewinningpass@promowinners.com" target="_blank" rel="noopener noreferrer">thewinningpass@promowinners.com</a>. If a winner does not do so, the Promoter reserves the right to not reissue the Prize or limit its value at its sole discretion.</p>
                  </li>
                </ol>
              </li>
              <li>
                <p>Provided no objection is received, the surname and county of the Level 1 Prize winners will be made available to anyone who requests them by writing to <strong>JS15029 Cadbury FC The Winning Pass, PO Box 704, Pinner, HA5 9PF</strong>, within 3 months of the closing date. In the event they win, winners may request that their surname and county are not published or request for the amount of information being published to be reduced during the Winner Notification process. Without prejudice, the Promoter will provide this information to the Advertising Standards Authority on reasonable request.</p>
              </li>
              <li>
                <p>The Promoter may request that the winners participate in reasonable publicity arising from the Promotion. Participation is at the winner’s discretion and is not a condition of Prize Acceptance.</p>
              </li>
              <li>
                <p>The Promoter seeks to run a fair and secure promotion and prevent abuse and cheating. If you enter in a way that is not consistent with these Terms & Conditions, your entries (and any of your associated aliases) will be disqualified. Any Prize awarded may be void and recoverable, and the Promoter reserves the right to bar you from entering future promotions for a period of at least six months. For this reason, the Promoter reserves the right at any point to:</p>
                <ol type="a">
                  <li><p>Verify the eligibility of entrants and/or provisional winners by requesting such information it considers reasonably necessary for this purpose. Entry or a Prize may be withheld until verification is completed.</p></li>
                  <li><p>Disqualify entries that are not made directly by the individual entering the Promotion. </p></li>
                  <li><p>Disqualify entries made using anonymous email services such as, but not limited to, GuerillaMail, Dispostable or Mailinator.</p></li>
                  <li><p>Disqualify bulk entries from individuals, trade, consumer groups or third parties, incomplete entries and entries submitted by macros or other automated means.</p></li>
                  <li><p>Disqualify entries beyond the maximum allowed, or those using techniques such as ‘script’, ‘brute force’, multiple SIM cards for text entries, masking identity by manipulating IP addresses, using aliases or identities other than their own or any other means.</p></li>
                  <li><p>Disqualify entrants who tamper with the entry process.</p></li>
                  <li><p>Disqualify entries which, in some other way, do not meet the requirements of these Terms & Conditions. </p></li>
                  <li><p>Cancel, modify or suspend the Promotion should it not be capable of running as planned for reasons including but not limited to tampering, unauthorised intervention, fraud, dishonesty, technical failures, or any other causes beyond the control of the Promoter which corrupt or affect the administration, security, fairness, integrity or proper conduct of this Promotion.</p></li>
                </ol>
              </li>
              <li>
                <p>The Prizes are not transferable or exchangeable and cannot be redeemed for monetary value or for any other form of compensation. If for any reason a Prize or any element of a Prize is not available, the Promoter reserves the right, at its sole discretion, to substitute      that Prize or element of a Prize for another of equal or greater value.</p>
              </li>
              <li>
                <p>No responsibility can be taken for entries which are lost, delayed, corrupted, damaged, misdirected or incomplete or which cannot be delivered for any technical, delivery or other reason. Proof of sending will not be accepted as proof of receipt. The Promoter does not guarantee continuous or secure access to <a href="https://thewinningpass.cadbury.co.uk" rel="noopener noreferrer" target="_blank">thewinningpass.cadbury.co.uk</a>. </p>
              </li>
              <li>
                <p>The Promoter and its associated agencies and companies are not liable for any loss (including, without limitation, indirect, special or consequential loss or loss of profits), expense or damage which is suffered or sustained (whether or not arising from any person’s negligence) in connection with this Promotion or accepting or using the/a Prize(s), except for any liability which cannot be excluded by law.  Nothing will exclude the Promoter's liability for death or personal injury as a result of its negligence.</p>
              </li>
              <li>
                <p>The Promoter will not be liable for any delay or failure to comply with its obligations for reasons beyond its reasonable control arising from but not limited to Acts of God, global or regional epidemic or pandemic, adverse weather conditions, fire, industrial dispute, war, terrorist activity, hostilities, political unrest, riots, civil commotion, plague or other natural calamities, or any other circumstances.</p>
              </li>
              <li>
                <p>This Promotion is administered and run by the Promoter and is not in any way administered by Arsenal FC, Chelsea FC, Leeds United, Liverpool FC, Manchester United, Celtic FC, Rangers FC and Tottenham Hotspur. By entering this Promotion you acknowledge that Arsenal FC, Chelsea FC, Leeds United, Liverpool FC, Manchester United, Celtic FC, Rangers FC and Tottenham Hotspur shall not have any liability to you in connection with the promotion. </p>
              </li>
              <li>
                <p>The Promoter shall comply with all applicable requirements of the Data Protection Act 2018, the General Data Protection Regulation 2016 and any successor legislation or other applicable law. The personal information entrants provide will be used by the Promoter for the purpose of conducting this promotion and in accordance with the Promoters’ Data Privacy Notice which can be found at <a href="https://www.cadbury.co.uk/privacy" rel="noopener noreferrer" target="_blank">www.cadbury.co.uk/privacy</a>. The Promoter may disclose entrants’ personal information to its contractors and agents to assist in conducting this Promotion (including any relevant promotional activity) or communicating with entrants.</p>
              </li>
              <li>
                <p>If any part of these Terms & Conditions are legally ruled to be invalid, illegal or unenforceable, the remainder will not be affected and will continue in full force and effect.</p>
              </li>
              <li>
                <p>By entering, you will be deemed to have agreed to be bound by these Terms & Conditions.</p>
              </li>
              <li>
                <p>If there is a discrepancy between these Terms & Conditions and those in any promotional material, these Terms & Conditions will prevail.</p>
              </li>
              <li>
                <p>These Terms & Conditions are governed by English law and their interpretation and application will be subject to the exclusive jurisdiction of the courts of England and Wales.</p>
              </li>
              <p><strong>Promoter:</strong> Mondelez Europe Services GmbH – UK Branch whose address is at Cadbury House, Sanderson Road, Uxbridge, UB8 1DH.</p>
              
              
            </ol>
          </div>
        </div>

        <div className='policies'>
          <div className='policies__content'>
          <div id="products-list" ref={appendix} className="type-h2">Appendix 1:</div>
              <ul id="reduced-list">
              <li>	Cadbury Flake 32g	</li>
              <li>	Cadbury Dairy Milk Fingers 114g	</li>
              <li>	Cadbury Dairy Milk Fingers 114g promo	</li>
              <li>	Trebor Extra Strong Peppermint 41.3g	</li>
              <li>	Trebor Softmint Peppermint 44.9g	</li>
              <li>	Trebor Softmint Spearmint 44.9g	</li>
              <li>	Cadbury Crunchie 40g	</li>
              <li>	Cabbury Snack Sandwich 136g	</li>
              <li>	Cadbury Twirl 43g	</li>
              <li>	Cadbury Picnic 48g	</li>
              <li>	MB sherbet lemon 192g	</li>
              <li>	MB wine gum singles	</li>
              <li>	Trebor Extra Strong Peppermint 4 Pack 165.2g	</li>
              <li>	Trebor Softmint Spearmint 4 Pack 179.6g	</li>
              <li>	Trebor Softmint Peppermint 4 Pack 179.6g	</li>
              <li>	Cadbury Giant Buttons 40g	</li>
              <li>	Cadbury Home and Away Wins Twirl Multipack 12CA	</li>
              <li>	Cadbury Twirl 3pk 129g	</li>
              <li>	Cadbury Twirl Orange 3pk 129g	</li>
              <li>	Cadbury Twirl 5pk 136g	</li>
              <li>	Cadbury Flake 4pk 102g	</li>
              <li>	Cadbury Starbar 49g	</li>
              <li>	Cadbury Boost 48.5g	</li>
              <li>	Cadbury Dark Milk Cocoa Nibs 85g	</li>
              <li>	Cadbury Oreo White 120g	</li>
              <li>	Cadbury Darkmilk Buttons 90g	</li>
              <li>	Cadbury Darkmilk Buttons 105g	</li>
              <li>	Cadbury Darkmilk Buttons Price Marked Pack 90g	</li>
              <li>	Cadbury 200 years Dairy Milk Bar 180g	</li>
              <li>	Cadbury Dairy Milk 180g	</li>
              <li>	Cadbury Dairy Milk Crunchie 180g	</li>
              <li>	Cadbury Home and Away Wins Wholenut Block 180g	</li>
              <li>	Cadbury 200 years Wholenut Bar 180g	</li>
              <li>	Cadbury Dairy Milk Wholenut 180g	</li>
              <li>	Cadbury Home and Away Wins Fruit & Nut Block 180g	</li>
              <li>	Cadbury 200 years Fruit & Nut Bar 180g	</li>
              <li>	Cadbury Dairy Milk Fruit and Nut 180g	</li>
              <li>	Cadbury Timeout Std 121g	</li>
              <li>	Cadbury Dairy Milk Caramel Nibbles 240g	</li>
              <li>	Cadbury Dairy Milk Buttons 240g	</li>
              <li>	Cadbury Bitsa Wispa 222g	</li>
              <li>	Cadbury White Buttons Price Marked Pack 95g	</li>
              <li>	Cadbury Dairy Milk Orange Buttons 110g	</li>
              <li>	MB mint fav 192g	</li>
              <li>	Cadbury Dairy Milk Orange Buttons 95g	</li>
              <li>	Cadbury White Buttons 95g	</li>
              <li>	Cadbury Bournville Fingers 114g	</li>
              <li>	Cadbury Double Decker 9pk 335.7g	</li>
              <li>	Cadbury Double Decker 4pk 149.2g	</li>
              <li>	Cadbury Dairy Milk Orange Buttons Price Marked Pack 95g	</li>
              <li>	Cadbury Double Decker Duo 74.6g	</li>
              <li>	Cadbury Double Decker 4pk 174.8g	</li>
              <li>	Cadbury Twirl Orange 43g	</li>
              <li>	Cadbury Twirl 43g (price marked pack)	</li>
              <li>	Cadbury Boost 48.5g (price marked pack)	</li>
              <li>	Cadbury Dairy Milk 45g (price marked pack)	</li>
              <li>	Cadbury Wipsa 36g (price marked pack)	</li>
              <li>	Cadbury Dairy Milk Marvellous Creations 47g (price marked pack)	</li>
              <li>	Cadbury Orange Fingers 114g	</li>
              <li>	Cadbury Milk Fingers 189g	</li>
              <li>	Cadbury Crunchie 40g (price marked pack)	</li>
              <li>	Cadbury Picnic 48.4g (price marked pack)	</li>
              <li>	Cadbury Starbar 49g (price marked pack)	</li>
              <li>	Cadbury Dairy Milk Orange 180g	</li>
              <li>	Cadbury Home and Away Wins Wispa Multipack 11CA	</li>
              <li>	Cadbury 200 years Wispa 4 pack	</li>
              <li>	Cadbury Wispa 4pk 111.6g	</li>
              <li>	Cadbury Boost Duo 63g	</li>
              <li>	Cadbury Boost 4pk 148g	</li>
              <li>	Cadbury Wispa Gold 4pk 153.2g	</li>
              <li>	Cadbury Wispa 9pk 213.3g	</li>
              <li>	Cadbury 200 years Wispa 4 pack	</li>
              <li>	Cadbury Wispa 4pk 94.8g	</li>
              <li>	Cadbury Wispa Duo 47.4g	</li>
              <li>	Cadbury Dairy Milk Duo 54.4g	</li>
              <li>	Cadbury 200 years Cadbury Dairy Milk 4 pack	</li>
              <li>	Cadbury Dairy Milk 4pk 108.8g	</li>
              <li>	Cadbury Home and Away Wins Cadbury Dairy Milk Multipack 15CA	</li>
              <li>	Cadbury Dairy Milk 4pk 134g	</li>
              <li>	Cadbury Dairy Milk 9pk 244.8g	</li>
              <li>	Cadbury Wispa Gold Duo 67g	</li>
              <li>	Cadbury Wispa Gold 4pk 134g	</li>
              <li>	Cadbury Mint Fingers 114g	</li>
              <li>	Cadbury Dairy Milk Mint 180g	</li>
              <li>	Cadbury Plant Bar Salted Caramel 90g	</li>
              <li>	Cadbury Plant Bar 90g	</li>
              <li>	Cadbury Darkmilk 150g	</li>
              <li>	Cadbury Caramilk Buttons 105g	</li>
              <li>	Cadbury Caramilk Buttons 90g	</li>
              <li>	Cadbury Dairy Milk Mega Buttons 192g	</li>
              <li>	Cadbury Dark Milk Hazelnut 85g	</li>
              <li>	Cadbury Caramilk 160g	</li>
              <li>	Cadbury Twirl Xtra 54g	</li>
              <li>	Cadbury Dairy Milk Salted Caramel 120g	</li>
              <li>	Cadbury Bournville Mint 100g	</li>
              <li>	SPK original carton 350g	</li>
              <li>	Cadbury Boost Multipack 4pk 126g Price Marked Pack	</li>
              <li>	Cadbury Boost 4pk 126g	</li>
              <li>	Cadbury Boost Multipack 4pk 148g	</li>
              <li>	Cadbury Dark Milk 90g	</li>
              <li>	Cadbury Dark Milk 80g	</li>
              <li>	Cadbury Dairy Milk Buttons Price Marked Pack 95g	</li>
              <li>	Cadbury Twirl Bites Price Marked Pack 95g	</li>
              <li>	Cadbury Dairy Milk Orange Buttons Price Marked Pack 95g	</li>
              <li>	Cadbury Bitsa Wispa Price Marked Pack 95g	</li>
              <li>	Cadbury White Buttons Price Marked Pack 95g	</li>
              <li>	Cadbury Oreo Bites Price Marked Pack 95g	</li>
              <li>	Cadbury Dairy Milk Mint Buttons Price Marked Pack 95g	</li>
              <li>	Cadbury Curly Wurly Squirlies Price Marked Pack 95g	</li>
              <li>	Cadbury Darkmilk Buttons Price Marked Pack 90g	</li>
              <li>	Cadbury Dairy Milk Nibbles Price Marked Pack 95g	</li>
              <li>	Cadbury Caramilk Buttons Price Marked Pack 90g	</li>
              <li>	Cadbury Dark Milk 80g £1.25 Price Marked Pack	</li>
              <li>	Cadbury Dairy Milk Orange 95g	</li>
              <li>	Cadbury Dairy Milk Orange 95g Price Marked Pack	</li>
              <li>	MB wine gums juicies 130g	</li>
              <li>	MB sports mix juicies 130g	</li>
              <li>	Cadbury Caramilk 4pk 122g	</li>
              <li>	Cadbury Timeout Orange 141g	</li>
              <li>	Cadbury Timeout Orange 121g	</li>
              <li>	Cadbury Bournville 100g Price Marked Pack	</li>
              <li>	Cadbury Bournville Old Jamaica 100g Price Marked Pack	</li>
              <li>	Cadbury Dairy Milk 95g	</li>
              <li>	Cadbury Bournville Orange 100g Price Marked Pack	</li>
              <li>	Cadbury Dairy Milk Fruit & Nut 95g	</li>
              <li>	Cadbury Dairy Milk Oreo Sandwich 95g Price Marked Pack	</li>
              <li>	Cadbury Dairy Milk Caramel 120g Price Marked Pack	</li>
              <li>	Cadbury Oreo White 120g Price Marked Pack	</li>
              <li>	Cadbury Dairy Milk Daim 120g Price Marked Pack	</li>
              <li>	Cadbury Dairy Milk Oreo 120g	</li>
              <li>	Cadbury White 90g Price Marked Pack	</li>
              <li>	Cadbury Caramilk 80g Price Marked Pack	</li>
              <li>	Cadbury Dairy Milk Chopped Nut 95g Price Marked Pack	</li>
              <li>	MB sports mix 130g	</li>
              <li>	MB mini gems 130g	</li>
              <li>	MB fizzy fish 130g	</li>
              <li>	MB wine gums bag 350g	</li>
              <li>	TNCC sour squirms 130g	</li>
              <li>	TNCC jelly snakes 130g	</li>
              <li>	MB Liqourice bag 350g	</li>
              <li>	MB jelly babies bag 350g	</li>
              <li>	TNCC dino mix 130g	</li>
              <li>	MB sports mix carton 350g	</li>
              <li>	MB jelly babies carton 350g	</li>
              <li>	MB liqourice carton 350g	</li>
              <li>	MB wine gums carton 350g	</li>
              <li>	Cadbury Milk Fingers Price Marked Pack 114g	</li>
              <li>	Cadbury Starbar Duo 74g	</li>
              <li>	Cadbury Dairy Milk Caramel 45g (price marked pack)	</li>
              <li>	MB jelly babies 130g	</li>
              <li>	MB liqourice 130g	</li>
              <li>	MB wine gums 130g	</li>
              <li>	Salted Caramel Fingers 114g	</li>
              <li>	Cadbury Timeout Std 141g	</li>
              <li>	Cadbury Dairy Milk Jelly Popping Candy 160g	</li>
              <li>	Cadbury Home and Away Wins Caramel Block 180g	</li>
              <li>	Cadbury 200 years Caramel Bar 180g	</li>
              <li>	Cadbury Dairy Milk Caramel 180g	</li>
              <li>	Cadbury Dairy Milk Buttons 330g	</li>
              <li>	Cadbury Dairy Milk Twisted Buttons 105g	</li>
              <li>	Cadbury White 100g	</li>
              <li>	Cadbury White 100g	</li>
              <li>	Cadbury White 90g	</li>
              <li>	Cadbury Dairy Milk Mint Buttons 110g	</li>
              <li>	Cadbury Dairy Milk Mint Buttons 95g Price Marked Pack	</li>
              <li>	Cadbury Caramilk 80g	</li>
              <li>	Cadbury Caramilk 90g	</li>
              <li>	Cadbury Nuttier Peanut Almond 120g	</li>
              <li>	Cadbury Nuttier Almond Cramberry 120g	</li>
              <li>	Cadbury Wispa Gold 48g (price marked pack)	</li>
              <li>	Cadbury Double Decker 54.5g (price marked pack)	</li>
              <li>	Cadbury Brunch Bournville 160g	</li>
              <li>	Cadbury Brunch Raisin 160g	</li>
              <li>	Cadbury Brunch Choc Chip 160g	</li>
              <li>	Cadbury Brunch Peanut 160g	</li>
              <li>	SPK Original 120g Price Marked Pack	</li>
              <li>	MB sports mix 130g Price Marked Pack	</li>
              <li>	MB wine gums 130g Price Marked Pack	</li>
              <li>	MB liqourice 130g Price Marked Pack	</li>
              <li>	SPK cola 130g	</li>
              <li>	SPK Original 130g	</li>
              <li>	SPK fruit mix 130g	</li>
              <li>	MB jelly babies 130g Price Marked Pack	</li>
              <li>	MB mini gems 130g Price Marked Pack	</li>
              <li>	SPK watermelon 130g	</li>
              <li>	MB fizzy fish 130g Price Marked Pack	</li>
              <li>	MB wine gums singles Price Marked Pack	</li>
              <li>	Cadbury Brunch Choc Chip Price Marked Pack 160g	</li>
              <li>	Cadbury Double Decker Multipack 4pk 149.2g Price Marked Pack	</li>
              <li>	Cadbury Crunchie 4pk 104.4g Price Marked Pack	</li>
              <li>	Cadbury Timeout Price Marked Pack 121g	</li>
              <li>	Cadbury Twirl Multipack 5pk 107.5g Price Marked Pack	</li>
              <li>	Cadbury Wispa Multipack 4pk 94.8g Price Marked Pack	</li>
              <li>	Cadbury Bournville 100g	</li>
              <li>	Cadbury Double Decker 54.5g	</li>
              <li>	Cadbury Marvellous Creations 47g	</li>
              <li>	Cadbury Dairy Milk Daim 120g	</li>
              <li>	Cadbury Bournville 180g	</li>
              <li>	Cadbury Home and Away Wins Crunchie Multipack 10CA	</li>
              <li>	Cadbury 200 years Crunchie 4 pack	</li>
              <li>	Cadbury Crunchie 4pk 128g	</li>
              <li>	Trebor Softmint Spearmint 44.9g Price Marked Pack	</li>
              <li>	Trebor Softmint Peppermint 44.9g Price Marked Pack	</li>
              <li>	Trebor Extra Strong Peppermint 41.3g Price Marked Pack	</li>
              <li>	Cadbury Crunchie Rocks 110g	</li>
              <li>	Cadbury Dairy Milk Caramel Nibbles 120g	</li>
              <li>	Cadbury Home and Away Wins Caramel Nibbles 120g	</li>
              <li>	Cadbury Dairy Milk Buttons 119g	</li>
              <li>	Cadbury Home and Away Wins Giant Buttons 119g	</li>
              <li>	Cadbury Bitsa Wispa 110g	</li>
              <li>	Cadbury Twirl Bites 110g	</li>
              <li>	Cadbury Caramel 4pk 148g	</li>
              <li>	MB cherry roll singles	</li>
              <li>	Cadbury Wispa Gold 48g	</li>
              <li>	MB murray mint 193g	</li>
              <li>	Cadbury Wipsa 36g	</li>
              <li>	Cadbury Dairy Milk 95g	</li>
              <li>	Cadbury Dairy Milk Fruit and Nut 95g	</li>
              <li>	MB Sports mix 165g	</li>
              <li>	MB Liqourice 165g	</li>
              <li>	MB Wine gums 165g	</li>
              <li>	MB Wine gums 165g	</li>
              <li>	MB jelly babies 165g	</li>
              <li>	MB jelly babies 165g	</li>
              <li>	Cadbury Bournville Buttons 95g	</li>
              <li>	Cadbury Bournville Dark Buttons 110g	</li>
              <li>	Cadbury Oreo Bites 95g	</li>
              <li>	Cadbury Oreo Bites Price Marked Pack 95g	</li>
              <li>	Cadbury Bournville Dark Buttons Price Marked Pack 95g	</li>
              <li>	Cadbury Dairy Milk Fruit & Nut 300g	</li>
              <li>	Cadbury Dairy Milk 300g	</li>
              <li>	Cadbury Curly Wurly Squirlies 95g	</li>
              <li>	Trebor Extra Strong Sugar Free Minis 12.6g	</li>
              <li>	Cadbury Curly Wurly Squirlies Price Marked Pack 95g	</li>
              <li>	Cadbury Dinky Deckers 120g	</li>
              <li>	Cadbury Dairy Milk Chopped Nut 95g	</li>
              <li>	Cadbury Dairy Milk Fruit & Nut 100g	</li>
              <li>	Trebor Extra Strong Sugar Free Minis 12.6g	</li>
              <li>	Cadbury Fudge Bites 110g	</li>
              <li>	Cadbury Bournville Orange 100g	</li>
              <li>	Cadbury Bournville Old Jamaica	</li>
              <li>	Cadbury Curly Wurly Squirlies 110g	</li>
              <li>	Cadbury Dairy Milk Peanut Caramel 278g	</li>
              <li>	Cadbury Dairy Milk 30% less sugar	</li>
              <li>	Cadbury White Buttons 110g	</li>
              <li>	Cadbury Bournville Orange 180g	</li>
              <li>	Cadbury Milk Fingers 115.8g	</li>
              <li>	Cadbury Picnic Bites 110g	</li>
              <li>	Cadbury Picnic 4pk 128g	</li>
              <li>	Cadbury Picnic Multipack 4pk 128g Price Marked Pack	</li>
              <li>	Cadbury Snack Shortcake 120g	</li>
              <li>	Cadbury Oreo Bites 110g	</li>
              <li>	Cadbury Mini Fingers 125g	</li>
              <li>	Cadbury Nibbly Fingers 125g	</li>
              <li>	Cadbury Tripple Choc Fingers 110g	</li>
              <li>	Cadbury Tripple Choc Fingers Price Marked Pack 110g	</li>
              <li>	Cadbury Twirl Price Marked Pack 95g	</li>
              <li>	Cadbury Twirl Bites 95g	</li>
              <li>	Cadbury Dairy Milk Caramel Nibbles 95g	</li>
              <li>	Cadbury Dairy Milk Caramel Nibbles price marked pack 95g	</li>
              <li>	Cadbury Dairy Milk Buttons Price Marked Pack 95g	</li>
              <li>	Cadbury Dairy Milk Buttons 95g	</li>
              <li>	Cadbury Bitsa Wispa Price Marked Pack 95g	</li>
              <li>	Cadbury Bitsa Wispa 95g	</li>
              <li>	Cadbury Twirl 10pk 215g	</li>
              <li>	Cadbury Dark Milk Salted Caramel 85g	</li>
              <li>	Cadbury Dairy Milk Oreo Sandwich 96g	</li>
              <li>	Cadbury Dairy Milk Wholenut 45g	</li>
              <li>	Cadbury Flake 4pk 80g	</li>
              <li>	Cadbury 200 years Twirl 5 pack	</li>
              <li>	Cadbury Twirl 5pk 107.5g	</li>
              <li>	Cadbury Picnic 4pk 152g	</li>
              <li>	Cadbury 200 years Crunchie 4 pack	</li>
              <li>	Cadbury Crunchie 4pk 104.4g	</li>
              <li>	Cadbury Dairy Milk Big Taste Oreo 300g	</li>
              <li>	Cadbury Dairy Milk Big Taste Triolade 300g	</li>
              <li>	Cadbury Dairy Milk Wholenut 120g	</li>
              <li>	Cadbury Dairy Milk Caramel 120g	</li>
              <li>	Cadbury Dairy Milk 45g	</li>
              <li>	Cadbury Dairy Milk Fruit & Nut 45g	</li>
              <li>	Cadbury Dairy Milk Caramel 45g	</li>
              <li>	Cadbury Dairy Milk Oreo 120g	</li>
              <li>	Cadbury Dairy Milk 100g	</li>
              <li>	Cadbury White 180g	</li>
              <li>	Cadbury White Mega Buttons 192g	</li>
              <li>	Cadbury White Fingers 114g	</li>
              <li>	Cadbury Caramilk Buttons price marked pack 90g	</li>
              <li>	Maynard bassetts Wine Gum Jucies 130g	</li>
              <li>	Cadbury Bournville Brunch Bar 160g	</li>
              <li>	Cadbury Raisin Brunch Bar 160g	</li>
              <li>	Cadbury Chocolate Brunch Bar 160g	</li>
              <li>	Cadbury Peanut Brunch Bar 160g	</li>
              <li>	Sour Patch Kids 130g	</li>
              <li>	Cadbury Twirl 5pk Price Marked Pack 107.5g	</li>
              <li>	Cadbury Boost 4pk Price Marked Pack 126g	</li>
              <li>	Cadbury Wispa 4pk Price Marked Pack 94.8g	</li>
              <li>	Cadbury Twirl Bites 85g	</li>
              <li>	Cadbury Bitsa Wispa 85g	</li>
              <li>	Cadbury Bitsa Wispa Price Marked Pack 85g	</li>
              <li>	Cadbury Dairy Milk Orange Buttons Price Marked Pack 85g	</li>
              <li>	Cadbury White Chocolate Buttons Price Marked Pack 85g	</li>
              <li>	Cadbury Caramilk Buttons Price Marked Pack 85g	</li>
              <li>	Cadbury Dairy Milk Orange Buttons 85g	</li>
              <li>	Cadbury Dairy Milk Caramel Nibbles Price Marked Pack 85g	</li>
              <li>	Cadbury White Chocolate Buttons 85g	</li>
              <li>	Cadbury Curly Wurly Squirlies Price Marked Pack 85g	</li>
              <li>	Cadbury Twirl Bites Price Marked Pack 85g	</li>
              <li>	Cadbury Oreo Bites Price Marked Pack 85g	</li>
              <li>	Cadbury Caramilk Buttons 85g	</li>
              <li>	Cadbury Dairy Milk Buttons Price Marked Pack 85g	</li>
              <li>	Cadbury Dairy Milk Buttons 85g	</li>
              <li>	Cadbury 200 years Buttons 85g	</li>
              <li>	Cadbury Dairy Milk Caramel Nibbles 85g	</li>
              <li>	Cadbury 200 years Caramel Nibbles 85g	</li>
              <li>	Cadbury 200 years Caramel Nibbles 120g	</li>
              <li>	Cadbury 200 years Buttons 119g	</li>
              <li>	Maynards Bassetts Sports Mix 165g	</li>
              <li>	Maynards Bassetts Wine Gums 165g	</li>
              <li>	Crunchie 9 pack	</li>
              <li>	Cadbury Freddo	</li>
              <li>	Cadbury Dairy Milk Freddo Caramel 19.5g	</li>
              <li>	Cadbury Dairy Milk Little Bar 18g	</li>
              <li>	Cadbury Fudge Singles 22g	</li>
              <li>	Cadbury Chomp 21g	</li>
              <li>	Cadbury Curly Wurly 21.8g	</li>
              <li>	Cadbury Freddo Price Mark Packed	</li>
              <li>	Cadbury Caramel Price Mark Packed	</li>
              <li>	Cadbury Chomp 21g Price Mark Packed	</li>
              <li>	Cadbury Fudge 22g Price Mark Packed	</li>
              <li>	Cadbury Freddo 90g 5pk Price Mark Packed	</li>
              <li>	Cadbury Freddo Caramel 97.5g 5pk	</li>
              <li>	Cadbury Buttons 70g 5pk	</li>
              <li>	Cadbury Fudge 110g 5pk	</li>
              <li>	Cadbury Chomp 105g 5pk	</li>
              <li>	Cadbury Curly Wurly 107.5g 5pk	</li>
              <li>	Cadbury Dairy Milk Little Bars 108g 6Pk	</li>
              <li>	Cadbury Freddo 90g Merlin 5pk	</li>
              <li>	Cadbury Freddo Caramel 97.5g Merlin 5pk	</li>
              <li>	Cadbury Fudge 110g 5pk Merlin	</li>
              <li>	Cadbury Curly Wurly 107.5g 5pk	</li>
              <li>	Cadbury Dairy Milk Little Bars 108g 6pk	</li>
              <li>	Cadbury Bournville 100g Price Marked Pack	</li>
              <li>	Cadbury Oreo White 120g Price Marked Pack	</li>
              <li>	Cadbury Caramilk 80g Price Marked Pack	</li>
              <li>	Cadbury Oreo Sandwich 96g Price Marked Pack	</li>
              <li>	Cadbury White 90g Price Marked Pack	</li>
              <li>	Cadbury Dairy Milk 95g Price Marked Pack	</li>
              <li>	Cadbury Dairy Milk Hazelnut 95g Price Marked Pack	</li>
              <li>	Cadbury Dairy Milk Fruit & Nut 95g Price Marked Pack	</li>
              <li>	Cadbury Caramilk 120g Price Marked Pack	</li>
              <li>	Cadbury Dairy Milk Daim 120g Price Marked Pack	</li>
              <li>	Cadbury Dairy Milk Oreo 120g Price Marked Pack	</li>
              <li>	Cadbury Dairy Milk Oreo 120g Price Marked Pack	</li>
              <li>	Cadbury Dairy Milk Oreo 120g Priced Mark Pack	</li>
              <li>	Cadbury Dairy Milk Fruit & Nut 95g Price Marked Pack	</li>
              <li>	Cadbury Dairy Milk Chopped Nut 95g Price Marked Pack	</li>
              <li>	Cadbury Dairy Milk Caramel 120g Price Marked Pack	</li>
              <li>	Cadbury Dairy Milk 95g Price Marked Pack	</li>
              <li>	Cadbury Dairy Milk Daim 120g Price Marked Pack	</li>
              <li>	Cadbury Dairy Milk Oreo 95g Price Marked Pack	</li>
              <li>	Cadbury White Oreo 120g Price Marked Pack	</li>
              <li>	Cadbury Bournville 100g Price Marked Pack	</li>
              <li>	Cadbury Bournville Old Jamaica 100g Price Marked Pack	</li>
              <li>	Cadbury Bournville Orange 100g Price Marked Pack	</li>
              <li>	Cadbury Dairy Milk Oreo 120g Price Marked Pack	</li>
              <li>	Cadbury White 90g Price Marked Pack	</li>
              <li>	Cadbury Caramilk 80g Price Marked Pack	</li>
              <li>	Cadbury Dairy Milk Caramel 180g (on pack promo)	</li>
              <li>	Cadbury Dairy Milk 180g (on pack promo)	</li>
              <li>	Cadbury Dairy Milk Fruit & Nut 180g (on pack promo)	</li>
              <li>	Cadbury Dairy Milk Wholenut 180g (on pack promo)	</li>
              <li>	Cadbury Roundie 150g	</li>
              <li>	Cadbury Timeout Price Marked Pack 121g	</li>
              <li>	Cadbury Mini Animals 139g	</li>
              <li>	Cadbury Choco Sandwich 260g	</li>
              <li>	Cadbury Crunchy Melts Oreo 312g	</li>
              <li>	Cadbury Crunchy Melts Choc 312g	</li>
              <li>	Cadbury Crunchy Melts Choc 156g	</li>
              <li>	Cadbury Crunchy Melts Choc 156g	</li>
              <li>	Cadbury Mini Animals 99.5g	</li>
              <li>	Cadbury Mini Fingers 96.5g	</li>
              <li>	Cadbury Dairy Milk 4 pack	</li>
              <li>	Cadbury Twirl 43g	</li>
              <li>	Cadbury Dairy Milk 4 pack	</li>
              <li>	Cadbury Twirl 43g	</li>
              <li>	Cadbury Wipsa 36g (price marked pack)	</li>
              <li>	Cadbury Crunchie 40g	</li>
              <li>	Cadbury Double Decker 54.5g	</li>
              <li>	Cadbury Boost 48.5g	</li>
              <li>	Cadbury Dairy Milk 45g	</li>
              <li>	Cadbury Dairy Milk Caramel 45g	</li>
              <li>	Cadbury Twirl Orange 43g	</li>
              <li>	Cadbury Dairy Milk Buttons 95g	</li>
              <li>	Cadbury Dairy Milk Nibbles 95g	</li>
              <li>	Cadbury Choc Chip Cookie 135g	</li>
              <li>	Cadbury Starbar 4 pack	</li>
              <li>	Cadbury Boost 4 pack	</li>
              <li>	Cadbury Boost 4 pack	</li>
              <li>	Cadbury Top Deck 110g	</li>
              <li>	Cadbury Top Deck 95g	</li>
              <li>	Cadbury Dairy Milk &More Nutty Praline Crisp 180g	</li>
              <li>	Cadbury Dairy Milk &More Caramel Nut Crunch 180g	</li>
              <li>	Cadbury Dairy Milk Caramel 180g	</li>
              <li>	Cadbury Dairy Milk 180g	</li>
              <li>	Cadbury Dairy Milk Whole Nut 180g	</li>
              <li>	Cadbury Dairy Milk Fruit & Nut 180g  	</li>
              <li>	Cadbury Dairy Milk Top 95g Price Marked Pack	</li>
              <li>	Cadbury Dairy Milk Buttons 184.8G	</li>
              <li>	Cadbury Dairy Milk Caramel Nibbles 186G	</li>
              <li>	Cadbury Bitsa Wispa 185.5G	</li>
              <li>	Maynards Bassetts Jelly Babies 165g	</li>
              <li>	Maynards bassetts Jelly Babies 130g	</li>
              <li>	Maynards bassetts Liquorice Allsorts 130g	</li>
              <li>	Maynards bassetts Sports Mix 130g	</li>
              <li>	Maynards bassetts Wine Gums 130g	</li>
              <li>	Maynards bassetts Wine Gums 130g	</li>
              <li>	Maynards bassetts Wine Gums 130g Price Marked pack	</li>
              <li>	Maynards bassetts Jelly Babies 130g Price Marked Pack	</li>
              <li>	Maynards bassetts Sports Mix 130g Price Marked Pack	</li>
              <li>	Maynards bassetts Liquorice Allsorts 130g Price Marked Pack	</li>
              <li>	Maynards Bassetts Mini Gems 130g Price Marked Pack	</li>
              <li>	Maynards Bassetts Wine Gums 350g	</li>
              <li>	Maynards Bassetts Jelly Babies 350g	</li>
              <li>	Maynards Bassetts Liquorice Allsorts 350g	</li>
              <li>	Maynards Bassetts Sports Mix Juices 130g	</li>
              <li>	Maynards bassetts Wine Gums Juices 130g	</li>
              <li>	The Natural Confectionary Company Jelly Snakes 130g	</li>
              <li>	The Natural Confectionary Company Jelly Squirms 130g	</li>
              <li>	The Natural Confectionary Company Dinosaurs 130g	</li>
              <li>	Sour Patch Kids 130g	</li>
              <li>	Sour Patch Kids Fruit Mix 130g	</li>
              <li>	Sour Patch Kids Cola 130g	</li>
              <li>	Sour Patch Kids 130g Price Marked Pack	</li>
              <li>	Maynards bassetts Wine Gums 130g Price Marked pack	</li>
              <li>	Maynards bassetts Jelly Babies 130g Price Marked Pack	</li>
              <li>	Maynards bassetts Sports Mix 130g Price Marked Pack	</li>
              <li>	Maynards bassetts Liquorice Allsorts 130g Price Marked Pack	</li>
              <li>	Maynards bassetts Mini Gems 130g Price Marked Pack	</li>
              <li>	Maynards Bassetts Fizzy Fish 130g Price Marked Pack	</li>
              <li>	Cadbury Eclairs 130g Price Marked Pack	</li>
              <li>	Sour Patch Kids 130g Price Marked Pack	</li>
              <li>	Maynards Bassetts Wine Gums 52g	</li>
              <li>	Trebor Soft Mints Peppermint 44.9g Price Marked Pack	</li>
              <li>	Trebor Soft Mints Spearmint 44g Price Marked Pack	</li>
              <li>	Trebor Soft Mints Xtra Strong Peppermint 41.3g Price Marked Pack	</li>
              <li>	Sour Patch Kids Strawberry 130g</li>
              <li>	Sour Patch Kids Strawberry 130g Price Marked Pack	</li>
              <li>	Cadbury Dairy Milk Buttons 85g	</li>
              <li>	Cadbury Dairy Milk Caramel Nibbles 85g	</li>
              <li>	Cadbury Dairy Milk Buttons 85g	</li>
              <li>	Cadbury Dairy Milk Buttons 95g	</li>
              <li>	Cadbury Caramilk Buttons 90g	</li>
              <li>	Cadbury Bitsa Wispa 95g	</li>
              <li>	Cadbury Twirl Bites 95g	</li>
              <li>	Cadbury White Buttons Price Marked Pack 85g	</li>
              <li>	Cadbury Twirl Bites Price Marked Pack 85g	</li>
              <li>	Cadbury Oreo Bites Price Marked Pack 85g	</li>
              <li>	Cadbury Dairy Milk Buttons Price Marked Pack 85g	</li>
              <li>	Cadbury Caramilk Buttons Price Marked Pack 85g	</li>
              <li>	Cadbury Dairy Milk Nibbles Price Marked Pack 85g	</li>
              <li>	Cadbury Bitsa Wispa Price Marked Pack 85g	</li>
              <li>	Cadbury Dairy Milk Orange Buttons Price Marked Pack 85g	</li>
              <li>	Cadbury Dairy Milk Buttons Price Marked Pack 85g	</li>
              <li>	Cadbury Curly Wurly Squirlies Price Marked Pack 85g	</li>
              <li>	Cadbury Dairy Milk Nibbles Price Marked Pack 85g	</li>
              <li>	Cadbury Dairy Milk Buttons Price Marked Pack 85g	</li>
              <li>	Cadbury Dairy Milk Buttons 100g	</li>
              <li>	Cadbury Dairy Milk Nibbles 100g	</li>
              <li>	Cadbury Twirl Bites 100g	</li>
              <li>	Cadbury Crunchie Rocks 100g	</li>
              <li>	Cadbury Dairy Milk Orange Buttons 100g	</li>
              <li>	Cadbury Oreo Bites 100g	</li>
              <li>	Cadbury Caramilk Buttons 100g	</li>
              <li>	Cadbury Dairy Milk Mint Buttons 100g	</li>
              <li>	Cadbury White Buttons 100g	</li>
              <li>	Cadbury Curly Wurly Squirlies 100g	</li>
              <li>	Cadbury Dairy Milk Twisted Buttons 100g	</li>
              <li>	Cadbury Dark Milk Buttons 100g	</li>
              <li>	Cadbury Fudge Minis 100g	</li>
              <li>	Cadbury Bournville Buttons 100g	</li>
              <li>	Cadbury Dairy Milk Nibbles 100g	</li>
              <li>	Cadbury Dairy Milk Buttons 100g	</li>
              <li>	Cadbury Dark Milk Cocoa Nibs 85g	</li>
              <li>	Cadbury Oreo White 120g	</li>
              <li>	Cadbury Dairy Milk 180g	</li>
              <li>	Cadbury Dairy Milk Crunchie 180g	</li>
              <li>	Cadbury Dairy Milk Wholenut 180g	</li>
              <li>	Cadbury Dairy Milk Fruit and Nut 180g	</li>
              <li>	Cadbury Dairy Milk Orange 180g	</li>
              <li>	Cadbury Dairy Milk Mint 180g	</li>
              <li>	Cadbury Plant Bar Salted Caramel 90g	</li>
              <li>	Cadbury Plant Bar 90g	</li>
              <li>	Cadbury Darkmilk 150g	</li>
              <li>	Cadbury Dark Milk Hazelnut 85g	</li>
              <li>	Cadbury Caramilk 160g	</li>
              <li>	Cadbury Dairy Milk Salted Caramel 120g	</li>
              <li>	Cadbury Bournville Mint 100g	</li>
              <li>	Cadbury Dark Milk 90g	</li>
              <li>	Cadbury Dark Milk 80g	</li>
              <li>	Cadbury Dark Milk 80g Price Marked Pack	</li>
              <li>	Cadbury Dairy Milk Orange 95g	</li>
              <li>	Cadbury Dairy Milk Orange 95g Price Marked Pack	</li>
              <li>	Cadbury Bournville 100g Price Marked Pack	</li>
              <li>	Cadbury Bournville Old Jamaica 100g Price Marked Pack	</li>
              <li>	Cadbury Dairy Milk 95g	</li>
              <li>	Cadbury Bournville Orange 100g Price Marked Pack	</li>
              <li>	Cadbury Dairy Milk Fruit & Nut 95g	</li>
              <li>	Cadbury Dairy Milk Oreo Sandwich 95g Price Marked Pack	</li>
              <li>	Cadbury Dairy Milk Caramel 120g Price Marked Pack	</li>
              <li>	Cadbury Oreo White 120g Price Marked Pack	</li>
              <li>	Cadbury Dairy Milk Daim 120g Price Marked Pack	</li>
              <li>	Cadbury Dairy Milk Oreo 120g	</li>
              <li>	Cadbury White 90g Price Marked Pack	</li>
              <li>	Cadbury Caramilk 80g Price Marked Pack	</li>
              <li>	Cadbury Dairy Milk Chopped Nut 95g Price Marked Pack	</li>
              <li>	Cadbury Dairy Milk Jelly Popping Candy 160g	</li>
              <li>	Cadbury Dairy Milk Caramel 180g	</li>
              <li>	Cadbury White 100g	</li>
              <li>	Cadbury White 100g	</li>
              <li>	Cadbury White 90g	</li>
              <li>	Cadbury Caramilk 80g	</li>
              <li>	Cadbury Caramilk 90g	</li>
              <li>	Cadbury Bournville 100g	</li>
              <li>	Cadbury Dairy Milk Daim 120g	</li>
              <li>	Cadbury Bournville 180g	</li>
              <li>	Cadbury Dairy Milk 95g	</li>
              <li>	Cadbury Dairy Milk Fruit and Nut 95g	</li>
              <li>	Cadbury Dairy Milk Fruit & Nut 300g	</li>
              <li>	Cadbury Dairy Milk 300g	</li>
              <li>	Cadbury Dairy Milk Chopped Nut 95g	</li>
              <li>	Cadbury Dairy Milk Fruit & Nut 100g	</li>
              <li>	Cadbury Bournville Orange 100g	</li>
              <li>	Cadbury Bournville Old Jamaica	</li>
              <li>	Cadbury Dairy Milk Peanut Caramel 278g	</li>
              <li>	Cadbur Dairy Milk 30% less sugar	</li>
              <li>	Cadbury Bournville Orange 180g	</li>
              <li>	Cadbury Dark Milk Salted Caramel 85g	</li>
              <li>	Cadbury Dairy Milk Oreo Sandwich 96g	</li>
              <li>	Cadbury Dairy Milk Big Taste Oreo 300g	</li>
              <li>	Cadbury Dairy Milk Big Taste Triolade 300g	</li>
              <li>	Cadbury Dairy Milk Wholenut 120g	</li>
              <li>	Cadbury Dairy Milk Caramel 120g	</li>
              <li>	Cadbury Dairy Milk Oreo 120g	</li>
              <li>	Cadbury Dairy Milk 100g	</li>
              <li>	Cadbury White 180g	</li>
              <li>	Cadbury Bournville 100g Price Marked Pack	</li>
              <li>	Cadbury Oreo White 120g Price Marked Pack	</li>
              <li>	Cadbury Caramilk 80g Price Marked Pack	</li>
              <li>	Cadbury Oreo Sandwich 96g Price Marked Pack	</li>
              <li>	Cadbury White 90g Price Marked Pack	</li>
              <li>	Cadbury Dairy Milk 95g Price Marked Pack	</li>
              <li>	Cadbury Dairy Milk Hazelnut 95g Price Marked Pack	</li>
              <li>	Cadbury Dairy Milk Fruit & Nut 95g Price Marked Pack	</li>
              <li>	Cadbury Caramilk 120g Price Marked Pack	</li>
              <li>	Cadbury Dairy Milk Daim 120g Price Marked Pack	</li>
              <li>	Cadbury Dairy Milk Oreo 120g Price Marked Pack	</li>
              <li>	Cadbury Dairy Milk Oreo 120g Price Marked Pack	</li>
              <li>	Cadbury Dairy Milk Oreo 120g Priced Mark Pack	</li>
              <li>	Cadbury Dairy Milk Fruit & Nut 95g Price Marked Pack	</li>
              <li>	Cadbury Dairy Milk Chopped Nut 95g Price Marked Pack	</li>
              <li>	Cadbury Dairy Milk Caramel 120g Price Marked Pack	</li>
              <li>	Cadbury Dairy Milk 95g Price Marked Pack	</li>
              <li>	Cadbury Dairy Milk Daim 120g Price Marked Pack	</li>
              <li>	Cadbury Dairy Milk Oreo 95g Price Marked Pack	</li>
              <li>	Cadbury White Oreo 120g Price Marked Pack	</li>
              <li>	Cadbury Bournville 100g Price Marked Pack	</li>
              <li>	Cadbury Bournville Old Jamaica 100g Price Marked Pack	</li>
              <li>	Cadbury Bournville Orange 100g Price Marked Pack	</li>
              <li>	Cadbury Dairy Milk Oreo 120g Price Marked Pack	</li>
              <li>	Cadbury White 90g Price Marked Pack	</li>
              <li>	Cadbury Caramilk 80g Price Marked Pack	</li>
              <li>	Cadbury Dairy Milk Caramel 180g (on pack promo)	</li>
              <li>	Cadbury Dairy Milk 180g (on pack promo)	</li>
              <li>	Cadbury Dairy Milk Fruit & Nut 180g (on pack promo)	</li>
              <li>	Cadbury Dairy Milk Wholenut 180g (on pack promo)	</li>
              <li>	Cadbury Top Deck 110g	</li>
              <li>	Cadbury Top Deck 95g	</li>
              <li>	Cadbury Dairy Milk &More Nutty Praline Crisp 180g	</li>
              <li>	Cadbury Dairy Milk &More Caramel Nut Crunch 180g	</li>
              <li>	Cadbury Dairy Milk Caramel 180g	</li>
              <li>	Cadbury Dairy Milk 180g	</li>
              <li>	Cadbury Dairy Milk Whole Nut 180g	</li>
              <li>	Cadbury Dairy Milk Fruit & Nut 180g  	</li>
              <li>	Cadbury Dairy Milk Top 95g Price Marked Pack	</li>
              <li>	Cadbury Dairy Milk 280g	</li>
              <li>	Cadbury Dairy Milk Fruit & Nut 280g  	</li>
              <li>	Trebor Extra Strong Peppermint 41.3g	</li>
              <li>	Trebor Softmint Peppermint 44.9g	</li>
              <li>	Trebor Softmint Spearmint 44.9g	</li>
              <li>	MB sherbet lemon 192g	</li>
              <li>	MB wine gum singles	</li>
              <li>	Trebor Extra Strong Peppermint 4 Pack 165.2g	</li>
              <li>	Trebor Softmint Spearmint 4 Pack 179.6g	</li>
              <li>	Trebor Softmint Peppermint 4 Pack 179.6g	</li>
              <li>	Trebor Extra Strong Peppermint 4 Pack 146g	</li>
              <li>	Trebor Softmint Spearmint 4 Pack 152g	</li>
              <li>	Trebor Softmint Peppermint 4 Pack 152g	</li>
              <li>	MB mint fav 192g	</li>
              <li>	SPK original carton 350g	</li>
              <li>	MB fizzy fish 130g	</li>
              <li>	MB sports mix carton 350g	</li>
              <li>	MB jelly babies carton 350g	</li>
              <li>	MB liqourice carton 350g	</li>
              <li>	MB wine gums carton 350g	</li>
              <li>	SPK watermelon 130g	</li>
              <li>	MB cherry roll singles	</li>
              <li>	MB murray mint 193g	</li>
              <li>	Trebor Extra Strong Sugar Free Minis 12.6g	</li>
              <li>	Trebor Extra Strong Sugar Free Minis 12.6g	</li>
              <li>	Maynard Bassetts Wine Gum Jucies 130g	</li>
              <li>	Sour Patch Kids 130g	</li>
              <li>	Maynards Bassetts Sports Mix 165g	</li>
              <li>	Maynards Bassetts Wine Gums 165g	</li>
              <li>	Maynards Bassetts Jelly Babies 165g	</li>
              <li>	Maynards Bassetts Jelly Babies 130g	</li>
              <li>	Maynards Bassetts Liquorice Allsorts 130g	</li>
              <li>	Maynards Bassetts Sports Mix 130g	</li>
              <li>	Maynards Bassetts Wine Gums 130g	</li>
              <li>	Maynards Bassetts Wine Gums 130g	</li>
              <li>	Maynards Bassetts Wine Gums 350g	</li>
              <li>	Maynards Bassetts Jelly Babies 350g	</li>
              <li>	Maynards Bassetts Liquorice Allsorts 350g	</li>
              <li>	Maynards Bassetts Sports Mix Juices 130g	</li>
              <li>	Maynards bassetts Wine Gums Juices 130g	</li>
              <li>	The Natural Confectionary Company Jelly Snakes 130g	</li>
              <li>	The Natural Confectionary Company Jelly Squirms 130g	</li>
              <li>	The Natural Confectionary Company Dinosaurs 130g	</li>
              <li>	Sour Patch Kids 130g	</li>
              <li>	Sour Patch Kids Fruit Mix 130g	</li>
              <li>	Sour Patch Kids Cola 130g	</li>
              <li>	Maynards Bassetts Wine Gums 130g Price Marked pack	</li>
              <li>	Maynards Bassetts Jelly Babies 130g Price Marked Pack	</li>
              <li>	Maynards Bassetts Sports Mix 130g Price Marked Pack	</li>
              <li>	Maynards Bassetts Liquorice Allsorts 130g Price Marked Pack	</li>
              <li>	Maynards Bassetts Mini Gems 130g Price Marked Pack	</li>
              <li>	Maynards Bassetts Fizzy Fish 130g Price Marked Pack	</li>
              <li>	Cadbury Eclairs 130g Price Marked Pack	</li>
              <li>	Sour Patch Kids 130g Price Marked Pack	</li>
              <li>	Maynards Bassetts Wine Gums 52g	</li>
              <li>	Trebor Soft Mints Peppermint 44.9g Price Marked Pack	</li>
              <li>	Trebor Soft Mints Spearmint 44g Price Marked Pack	</li>
              <li>	Trebor Soft Mints Xtra Strong Peppermint 41.3g Price Marked Pack	</li>

              </ul>
          </div>
        </div>
      </div>
    </section>
  </>;
}